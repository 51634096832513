import { Injectable } from "@angular/core";
import { environment } from "./../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map, filter, catchError, mergeMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class EmailService {
  constructor(private http: HttpClient) {}

  sendEmail(data) {
    // debugger
    return this.http
      .post(`${environment.base_url}`, data)
      .pipe(map((res: Response) => res));
  }
}
