import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { EmailService } from './../../services/email.service';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"],
})
export class ContactComponent implements OnInit {
  title = 'Join as a LIC Agent in Hyderabad | Request nearest LIC Development Officer..';
  constructor(
    private toastr: ToastrService,
    private emailService: EmailService,
    private titleService: Title,
    private metaTagService: Meta,
    private router : Router,

    ) {}

    showHide =true;

  ngOnInit() {
    this.onActivate(event);
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Life Insurance Corporation of India – S.Thirupathi Naidu Development Officer Address:Kukatpally, Hyderabad - 500072, Contact:9959471584,Email:naidulic1838@gmail.com' }
    );
  }

  contactData: any = {};
  submitContact() {
    this.showHide =false;
    if (
      !this.contactData.name ||
      !this.contactData.name.trim() ||
      !this.contactData.mobile ||
      !this.contactData.mobile.trim() 
    ) {
      this.toastr.error("Please fill all the mandatory fields");
      return;
    }
    this.emailService.sendEmail(this.contactData).subscribe((resp: any)=>{
      this.toastr.success(resp.message);
      this.showHide =true;
      this.router.navigate(['/Thanku']);

      this.contactData = {};
    }, (err)=>{
      this.toastr.error("Something went wrong.")
    })
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }   
  
}
