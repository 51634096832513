import { Component, OnInit,HostListener } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { EmailService } from './../../services/email.service';
import { Router } from '@angular/router';

declare var $;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(
    private toastr: ToastrService,
    private emailService: EmailService,
    private router : Router,

  ) { }

  showHide =true;

  ngOnInit() {
   
    $(document).ready(function () {
      $('ul li a').click(function () {
        $('li a').removeClass("active");
        $(this).addClass("active");
      });
    });
  }
  
  contactData: any = {};
  submitContact() {
    this.showHide =false;
    if (
      !this.contactData.name ||
      !this.contactData.name.trim() ||
      !this.contactData.mobile ||
      !this.contactData.mobile.trim() 
    ) {
      this.toastr.error("Please fill all the mandatory fields");
      return;
    }
    this.emailService.sendEmail(this.contactData).subscribe((resp: any)=>{
      this.toastr.success(resp.message);
      this.showHide =true;
      this.router.navigate(['/Thanku']);

      this.contactData = {};
    }, (err)=>{
      this.toastr.error("Something went wrong.")
    })
  } 

  @HostListener('document:click')
  clickout() {
    $( "#navBarId" ).removeClass( "in" )
  }


  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  } 

}
