import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-benfits',
  templateUrl: './benfits.component.html',
  styleUrls: ['./benfits.component.css']
})
export class BenfitsComponent implements OnInit {
  title = 'benefits of lic agent | Rewarding Career. You will help people realise their dreams by fulfilling their financial goals';
  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.onActivate(event);
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'your Profession will be better than a Job! Good, LIC Agent Profession is a Self Employment, which need a Creative, Hard and Smart work! ... If You are Determined to become an Agent, Approach Any LIC Development Officer' }
    );
  }
  onActivate(event) {
    window.scroll(0, 0);
  }

}
