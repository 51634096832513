import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './Components/landing-page/landing-page.component';
import { AboutComponent } from './Components/about/about.component';
import { DownloadsComponent } from './Components/downloads/downloads.component';
import { ContactComponent } from './Components/contact/contact.component';
import { BenfitsComponent } from './Components/benfits/benfits.component';
import { PoliciesComponent } from './Components/policies/policies.component';
import { ThankuComponent } from './Components/thanku/thanku.component';
import { TeramConditionsComponent } from './Components/teram-conditions/teram-conditions.component';

const routes: Routes = [
 
  { path: 'home', component:LandingPageComponent}, 
  { path: 'about', component:AboutComponent}, 
  { path: 'downloads', component:DownloadsComponent}, 
  { path: 'benfits', component:BenfitsComponent}, 
  { path: 'policies', component:PoliciesComponent}, 
  { path: 'contact', component:ContactComponent}, 
  { path: 'Thanku', component:ThankuComponent}, 
  { path: 'tearms-and-condition', component:TeramConditionsComponent}, 

  { path: '', component:LandingPageComponent}, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
