import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.css']
})
export class DownloadsComponent implements OnInit {
  title = 'Become Lic Agent In Hyderabad';

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.onActivate(event);
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'LIC Agency offers maximum free time due to guaranteed lifetime renewal income, which helps you to improve the overall quality of your life with a strong chance of early retirement' }
    );
  }
  onActivate(event) {
    window.scroll(0, 0);
  }
}
