import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HttpClientModule } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './Components/header/header.component';
import { LandingPageComponent } from './Components/landing-page/landing-page.component';
import { FooterComponent } from './Components/footer/footer.component';
import { AboutComponent } from './Components/about/about.component';
import { DownloadsComponent } from './Components/downloads/downloads.component';
import { ContactComponent } from './Components/contact/contact.component';
import { BenfitsComponent } from './Components/benfits/benfits.component';
import { PoliciesComponent } from './Components/policies/policies.component';
import { ToastrModule } from 'ngx-toastr';
import { ThankuComponent } from './Components/thanku/thanku.component';
import { TeramConditionsComponent } from './Components/teram-conditions/teram-conditions.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LandingPageComponent,
    FooterComponent,
    AboutComponent,
    DownloadsComponent,
    ContactComponent,
    BenfitsComponent,
    PoliciesComponent,
    ThankuComponent,
    TeramConditionsComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule
  ],
  
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
