import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { EmailService } from './../../services/email.service';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  title = 'information about lic agent | Life insurance, Health Insurance, and Investment Management are the facilities which this public sector unit offer to its customers';
  constructor(
    private toastr: ToastrService,
    private emailService: EmailService,
    private titleService: Title,
    private metaTagService: Meta,
    private router : Router,

  ) { }

  showHide = true;

  ngOnInit() {
    this.onActivate(event);
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'We offer one of the best remuneration systems in the industry that not only takes care of your current earnings, but also guarantees an earning for the future. Whats more, you can set your own income targets with a potential to earn as much as you want for the rest of your life.' }
    );
  }

  contactData: any = {};
  submitContact() {
    this.showHide =false;
    if (
      !this.contactData.name ||
      !this.contactData.name.trim() ||
      !this.contactData.mobile ||
      !this.contactData.mobile.trim() 
    ) {
      this.toastr.error("Please fill all the mandatory fields");
      return;
    }
    this.emailService.sendEmail(this.contactData).subscribe((resp: any)=>{
      this.toastr.success(resp.message);
      this.showHide = true;
      this.router.navigate(['/Thanku']);

      this.contactData = {};
    }, (err)=>{
      this.toastr.error("Something went wrong.")
    })
  }
  
  onActivate(event) {
    window.scroll(0, 0);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  } 

  
}
