import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teram-conditions',
  templateUrl: './teram-conditions.component.html',
  styleUrls: ['./teram-conditions.component.css']
})
export class TeramConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.onActivate(event);
  }
  onActivate(event) {
    window.scroll(0, 0);
  }
}
