import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thanku',
  templateUrl: './thanku.component.html',
  styleUrls: ['./thanku.component.css']
})
export class ThankuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.onActivate(event);
  }
  onActivate(event) {
    window.scroll(0, 0);
  }
}
